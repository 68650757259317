import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapiGuideComponent } from './capi-guide.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonComponent, NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormControlComponent, NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';


@NgModule({
  declarations: [
    CapiGuideComponent
  ],
  exports: [
    CapiGuideComponent
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzModalModule
  ]
})
export class CapiGuideModule { }
