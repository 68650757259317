import { Component, ViewEncapsulation, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {

  element: any;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement
  }

  ngOnInit(): void {
  }

  public openModal() {
    document.appendChild(this.element);
  }

}
