import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceOsModalComponent } from './device-os-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DroplistModule } from '../droplist/droplist.module';
import { FormsModule } from '@angular/forms';
import { NzSpinModule } from 'ng-zorro-antd/spin';



@NgModule({
  declarations: [
    DeviceOsModalComponent,
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzInputModule,
    NzMenuModule,
    NzCheckboxModule,
    NzFormModule,
    NzIconModule,
    NzButtonModule,
    DroplistModule,
    FormsModule,
    NzSpinModule
  ],
  exports: [
    DeviceOsModalComponent
  ]
})
export class DeviceOsModalModule { }
