import { environment } from '../../environments/environment';
const audiencePath = `${environment.audiencePath}`;
export const subscriptionPlanTierFeatures = {
  enterprise: [
    'Access to unlimited users',
    'Unlimited Profile Enrichment',
    '5,000,000 FCAPI events',
    'Unlimited survey responses',
    '50,000 free SMS',
  ],
};

export const subscriptionTierProductNameMap: any = {
  Users: 'users',
  'Profile Enrichment': 'Profile Enrichment',
  'Facebook CAPI': 'FCAPI events',
  'Smart Survey': 'survey responses',
  'Mobile Offline': 'free SMS',
};

export const currencySymbolMap: any = {
  USD: '$',
  NGN: '₦',
};

export const currencyCountryMap: any = {
  Kenya: 'USD',
  Nigeria: 'NGN',
};

export const topupPlanNameMap: any = {
  'Mobile Offline': {
    name: 'SMS units',
    unitName: 'SMS messages',
  },
  WhatsApp: {
    name: 'WhatsApp units',
    unitName: 'WhatsApp messages',
  },
  Email: {
    name: 'email units',
    unitName: 'emails',
  },
  Social: {
    name: 'Social units',
    unitName: 'social messages',
  },
  USSD: {
    name: 'USSD units',
    unitName: 'ussds',
  },
  'Facebook CAPI': {
    name: 'Facebook CAPI',
    unitName: 'Facebook CAPI events',
  },
};

//Audeince Builder Constants//

export const potentialCountBody: any = {
  index: audiencePath,
  smartPhone: false,
  getCount: true,
  getSegment: false,
  sendSMS: false,
  featurePhone: false,
  datasource: 'terragon',
  CampaignMaster: { include: [], exclude: [] },
  AgeMaster: { include: [], exclude: [] },
  GenderMaster: { include: [], exclude: [] },
  LocationMaster: { include: [], exclude: [] },
  DeviceMaster: { include: [], exclude: [] },
  AccountBranchMaster: { include: [], exclude: [] },
  OperatingSystemMaster: { include: [], exclude: [] },
  InterestMaster: { include: [], exclude: [] },
  CustomerValueMaster: { include: [], exclude: [] },
  MNO: { include: [], exclude: [] },
  Bank: false,
  VasSubscriber: false,
  BlackListed: {
    include: [
      {
        id: 'false',
        type: 'blackListed',
      },
    ],
  },
};

export const potentialCountBodyCopy: any = {
  index: audiencePath,
  smartPhone: false,
  getCount: true,
  getSegment: false,
  sendSMS: false,
  featurePhone: false,
  datasource: 'terragon',
  CampaignMaster: { include: [], exclude: [] },
  AgeMaster: { include: [], exclude: [] },
  GenderMaster: { include: [], exclude: [] },
  LocationMaster: { include: [], exclude: [] },
  DeviceMaster: { include: [], exclude: [] },
  AccountBranchMaster: { include: [], exclude: [] },
  OperatingSystemMaster: { include: [], exclude: [] },
  InterestMaster: { include: [], exclude: [] },
  CustomerValueMaster: { include: [], exclude: [] },
  MNO: { include: [], exclude: [] },
  Bank: false,
  VasSubscriber: false,
  BlackListed: {
    include: [
      {
        id: 'false',
        type: 'blackListed',
      },
    ],
  },
};

export const genderArray: any[] = [
  {
    id: '/m',
    name: 'Male',
    type: 'GenderMaster',
    add: 1,
    level: 1,
    lowerLimit: 1,
    upperLimit: 100,
  },
  {
    id: '/f',
    name: 'Female',
    type: 'GenderMaster',
    add: 2,
    level: 1,
    lowerLimit: 1,
    upperLimit: 100,
  },
];

export const ageArray: any[] = [
  {
    id: '/custom',
    name: 'Custom',
    type: 'AgeMaster',
    level: 1,
    lowerLimit: 1,
    upperLimit: 100,
  },
];

export const socialCircle: any[] = [
  {
    pathName: '/very high',
    level: 1,
    name: 'very high',
    id: '/4',
    type: 'CustomerValueMaster',
    icon: 'fal fa-signal',
    description: 'Net spend of over ₦1M',
  },
  {
    pathName: '/high',
    level: 1,
    name: 'high',
    id: '/5',
    type: 'CustomerValueMaster',
    icon: 'fas fa-signal-alt',
    description: 'Net spend of ₦500K to ₦999K',
  },
  {
    pathName: '/medium',
    level: 1,
    name: 'medium',
    id: '/2',
    type: 'CustomerValueMaster',
    icon: 'fas fa-signal-alt-3',
    description: 'Net spend of ₦50K to ₦500K',
  },
  {
    pathName: '/low',
    level: 1,
    name: 'low',
    id: '/3',
    type: 'CustomerValueMaster',
    icon: 'fas fa-signal-alt-1',
    description: 'Net spend of below ₦50K',
  },
];

export const mnoArray: any[] = [
  { id: 'ng_mtn', value: 1, name: 'MTN' },
  { id: 'ng_airtel', value: 1, name: 'Airtel' },
  { id: 'ng_9mobile', value: 1, name: '9Mobile' },
  { id: 'ng_glo', value: 1, name: 'Glo' },
];

export const panel: any[] = [
  {
    active: false,
    id: 'retarget',
    name: 'Audience retargeting',
  },
  {
    active: false,
    id: 'gender',
    name: 'Gender',
    extraText: 'male, female',
    data: [
      { label: 'All', value: 2 },
      { label: 'Male', value: 0 },
      { label: 'Female', value: 1 },
    ],
  },
  {
    active: false,
    id: 'age',
    name: 'Age Range',
    extraText: '1-100',
    data: [
      { label: 'All', value: 'all' },
      { label: 'Select range', value: 'range' },
    ],
  },
  {
    active: false,
    id: 'economicRate',
    name: 'Economic Rating',
    extraText: '1-100',
    data: [
      {
        label: 'Very high',
        value: 'very high',
        subLabel: 'Mobile spend of >5500 Monthly',
        checked: false,
      },
      {
        label: 'High',
        value: 'high',
        subLabel: 'Mobile spend of  > 3235 Monthly',
        checked: false,
      },
      {
        label: 'Medium',
        value: 'medium',
        subLabel: 'Mobile spend of > 874 monthly',
        checked: false,
      },
      {
        label: 'Low',
        value: 'low',
        subLabel: 'Mobile spend of > 187 Monthly',
        checked: false,
      },
    ],
  },
  {
    active: false,
    id: 'location',
    name: 'Location',
  },
  {
    active: false,
    id: 'interest',
    name: 'Interest',
    childPanel: [
      {
        active: false,
        name: 'Interests to include',
      },
      {
        active: false,
        name: 'Interests to exclude',
      },
    ],
  },
  {
    active: false,
    id: 'networks',
    name: 'Network Operators',
    data: [
      { id: 'ke_airtel', value: 384267, name: 'Airtel' },
      { id: 'ke_default', value: 440746, name: 'Default' },
      { id: 'ke_equitel', value: 1897, name: 'Equitel' },
      { id: 'ke_safaricom', value: 7758256, name: 'Safaricom' },
    ],
  },
  {
    active: false,
    id: 'operators',
    name: 'Operating System',
  },
  {
    active: false,
    id: 'deviceModels',
    name: 'Device Models',
  },
  {
    active: false,
    id: 'deviceTypes',
    name: 'Device Type',
    data: [
      { id: 'featurePhone', name: 'Feature Phone' },
      { id: 'smartPhone', name: 'Smart phone' },
    ],
  },
];

// export const kenyaBasedPanels : any[] = ['gender', 'age', 'location', 'networks', 'operators', 'deviceModels', 'deviceTypes'];
// export const nigeriaBasedPanels : any[] = ['gender', 'age', 'economicRate', 'location', 'interest', 'networks', 'operators', 'deviceModels', 'deviceTypes'];
export const kenyaBasedPanels: any[] = [
  'retarget',
  'gender',
  'age',
  'location',
  'networks',
  'operators',
  'deviceModels',
  'deviceTypes',
];
export const nigeriaBasedPanels: any[] = [
  'retarget',
  'gender',
  'age',
  'economicRate',
  'location',
  'interest',
  'networks',
  'operators',
  'deviceModels',
  'deviceTypes',
];

export const priceList: any[] = [
  { network: 'MTN', country: 'Nigeria', pCost: 1.83, tCost: 2.14 },
  { network: 'Airtel', country: 'Nigeria', pCost: 1.9, tCost: 2.14 },
  { network: 'Glo', country: 'Nigeria', pCost: 1.63, tCost: 2.14 },
  { network: '9Mobile', country: 'Nigeria', pCost: 1.39, tCost: 2.37 },
  { network: 'All', country: 'Global', pCost: 3.0, tCost: 3.0 },
];
