import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CreateCampaignModalComponent } from './createCampaign-modal.component';



@NgModule({
  declarations: [
    CreateCampaignModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule 
  ],
  exports: [
    CreateCampaignModalComponent
  ]
})
export class CreateCampaignModalModule { }
