<div class="modal">
  <nz-modal [(nzVisible)]="open" [nzFooter]='null' nzTitle="" nzWidth="642px" nzClassName="modal" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
      <div>
        <section class="illustration flex justify-center">
          <img src="../../../../assets/img/capi-guide.svg" />
        </section>
        <section class="text-center">
          <h2 class="mt-6 mb-3">{{stepContent.title}}</h2>
          <p style="min-height: '90px';">{{stepContent.text}}</p>

          <div class="flex items-center justify-center py-4">
            <i class="step-icon mr-3" [ngClass]="{'active': currentStep == 1}"></i>
            <i class="step-icon mr-3" [ngClass]="{'active': currentStep == 2}"></i>
            <i class="step-icon" [ngClass]="{'active': currentStep == 3}"></i>
          </div>

          <div class="px-4 flex justify-between">
            <button nz-button nzSize="large" nzType="default" style="float: left;"  (click)="handleCancel()" *ngIf="currentStep !== 3">
              Skip
            </button>
            <button nz-button nzSize="large" nzType="primary" style="float: right;" (click)="next()" class="ml-auto">
              {{currentStep !== 3 ? 'Next' : 'Got it'}}
            </button>
          </div>
        </section>
      </div>
    </ng-container>
  </nz-modal>
</div>