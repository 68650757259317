import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { HeaderComponent } from './header.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NotificationDatePipe } from 'src/app/pipe/notification-date.pipe';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationDatePipe
  ],
  imports: [
    CommonModule,
    NzModalModule ,
    NzGridModule,
    NzPopoverModule,
    NzButtonModule,
    NzBreadCrumbModule,
    RouterModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }