import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DroplistComponent } from './droplist.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';



@NgModule({
  declarations: [
    DroplistComponent
  ],
  imports: [
    CommonModule,
    NzSpinModule
  ],
  exports: [
    DroplistComponent
  ]
})
export class DroplistModule { }
