import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * The component works as a paginator which can be triggered by calling the selector
 * Input : currentPage (number) - Is the current page number to be selected when the component is initiated.
 * Input : totalCount (number) - Is the length of the iterable on which we are adding pagination.
 * Output : pageChange (pageData) - Returns the current page number and count per page to the calling component.
 *
 * The component can be used both for front end and server side pagination.
 * If used in the front end, method to filter the iterable should be implemented using the current page number and count per page.
 * If used in the server side rendering, current page number and count per page can be directly used to make API calls.
 *
 * Sample of the front end method to be implemented is given below :
 *
 * data : Iterable;
 * pageChange(pageData) {
    this.paginatedData = this.data.slice(((pageData.currentPage - 1) * pageData.countPerPage), pageData.currentPage * pageData.countPerPage);
  }
 */
interface pageData {
  currentPage: number;
  countPerPage: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
})
export class PaginatorComponent implements OnInit {
  @Input() currentPage: number = 0;
  @Input() totalCount: number = 0;
  @Input() countPerPage: number = 10;
  @Input() countLoading: boolean = false;
  @Output() pageChange: EventEmitter<pageData> = new EventEmitter();

  // countPerPage = 10;
  pageNumber = 1;
  start = 1;
  end = 10;
  pageData: pageData = { countPerPage: 10, currentPage: 1 };
  totalPageCount: number = 0;
  pageCount: any = 0;
  pageLimit = 5;
  pageShown: number = 0;
  midPage: number = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.totalPageCount = Math.ceil(this.totalCount / this.countPerPage);
    // this.pageNumber = 1;
    this.pageShown = this.pageLimit - 2;
    this.midPage = Math.ceil(this.pageShown / 2);
    this.pageCount = this.pageCounter(this.pageNumber);
    this.start = 1;
    this.end = 10;
    this.updateViewCount();
  }

  jump(pageNumber?: any) {
    pageNumber ? (this.pageNumber = pageNumber) : null;
    if (!this.isPageShown(this.pageNumber)) {
      this.pageCount = this.pageCounter(this.pageNumber);
    }
    this.pageData.countPerPage = this.countPerPage;
    this.pageData.currentPage = this.pageNumber;
    this.updateViewCount();
    this.pageChange.emit(this.pageData);
  }

  next() {
    if (this.pageNumber === this.totalPageCount) {
      return;
    }
    this.pageNumber++;
    this.pageCount = this.pageCounter(this.pageNumber);
    this.pageData.countPerPage = this.countPerPage;
    this.pageData.currentPage = this.pageNumber;
    this.updateViewCount();
    this.pageChange.emit(this.pageData);
  }

  previous() {
    if (this.pageNumber === 1) {
      return;
    }
    this.pageNumber--;
    this.pageCount = this.pageCounter(this.pageNumber);
    this.pageData.countPerPage = this.countPerPage;
    this.pageData.currentPage = this.pageNumber;
    this.updateViewCount();
    this.pageChange.emit(this.pageData);
  }

  pageCounter(pageNumber: any) {
    this.pageShown = this.pageLimit - 2;
    if (this.totalPageCount > this.pageLimit) {
      // if(pageNumber === 1){
      //   return this.getPageArray(this.pageLimit-2,1);
      // }else if(pageNumber === this.totalPageCount){
      //   return this.getPageArray(this.pageLimit-2,this.totalPageCount-this.pageLimit-2);
      // }else{

      // }
      let start = pageNumber - Math.ceil(this.pageShown / 2);
      return this.getPageArray(this.pageShown, start < 1 ? 1 : start);
    } else {
      return this.getPageArray(this.totalPageCount, 1);
      //return new Array(this.totalPageCount);
    }
  }

  getPageArray(arraySize: any, arrayStart: any) {
    let size = 0;
    let pArray = [];
    while (size < arraySize) {
      pArray.push(arrayStart++);
      size++;
    }
    return pArray;
  }

  updateViewCount() {
    this.start = (this.pageNumber - 1) * this.countPerPage + 1;
    let end = this.pageNumber * this.countPerPage;

    if (!this.countLoading && this.totalCount < end) {
      this.end = this.totalCount;
    } else {
      this.end = end;
    }
  }

  isPageShown(pagenumber: any) {
    return this.pageCount.findIndex((number: any) => number === pagenumber) > -1
      ? true
      : false;
  }

  // navigatePage(page: number) {
  //   if (page >= 1 && page <= Math.ceil(this.totalItemsBeingPaginated / this.pageSize)) {
  //     this.currentPage = page;
  //     this.pageChange.emit(page);
  //   }
  // }
}
