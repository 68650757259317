import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from '../_services/auth.service';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })
};


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if account is logged in and request is to the api url
        let user = this.authService.getUser();
        let tenantId = (user?.business_id ? user.business_id : '-1') + (user?.id ? '_' + user.id : '');
        //request.headers.append('X-Tenant-User-Id', tenantId)
        let authReq = request;
        const token = this.authService.getToken() == null ? this.authService.getBusinessToken() : this.authService.getToken();
        const account = this.authService.accountValue;
        const isLoggedIn = account && this.authService.getToken();
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        // if (isLoggedIn && isApiUrl) {
        //     authReq = request.clone({
        //         headers : request.headers.append('Authorization', `Bearer ${this.authService.getToken()}`)
        //     });
        // }

        if (token != null) {
            authReq = request.clone({ headers: request.headers.append('Authorization', `Bearer ${token}`) });
        }
        authReq = authReq.clone({ headers: authReq.headers.append('X-Tenant-User-Id', tenantId) });
        return next.handle(authReq);
    }
}