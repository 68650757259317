import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../_services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private notification: NzNotificationService, private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // if (err.status == 401 && this.authService.accountValue) {
            if (err.status == 401) {
                this.authService.logout();
                this.router.navigate(['/auth/login']);
            }
            if (err.status == 403) {
                this.router.navigateByUrl(`/error/index/:${err.status}`);
            }
            if (err.status == 404) {
                this.router.navigateByUrl(`/error/index/:${err.status}`);
            }
            let error;
            // const error = (err && err.error && err.error.message) || err.Message;
            if (err.status == 412) {
                error = err;
            } else {
                error = err.error;
            }

            // this.createNotification('error', err.error.message);
            return throwError(error);
        }))
    }

    createNotification(type: string, data: string): void {
        this.notification.create(
            type,
            data,
            data
        );
    }
}