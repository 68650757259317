<div class="dropList">
    <div class="dropListLabel" (click)="setActiveOption(data._id)">
        <p>{{data._source.name}}</p>
        <svg *ngIf="state==='closed'" width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.85918 1.22588L5.48434 5.79386" stroke="#008138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.15031 1.20699L5.48487 5.79301" stroke="#008138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>       
        <svg *ngIf="state==='open'"  width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.04311 5.12493L4.91822 1.86209" stroke="#008138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.82109 5.13787L4.91721 1.86213" stroke="#008138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>                 
    </div>
    <div class="checkList" *ngIf="state==='open'">
        <ng-container *ngIf="subData.length > 0">
            <div class="checkItem">
                <span class="checkText">
                    <p>{{data._source.name}} (All Models)</p>
                </span>
                <span class="check">
                    <input nz-input type="checkbox" class="mr-4" nzStyle="{width: '50px'}" [checked]="isSelected(data)" (change)="onCheckBoxChangeLevel($event,data._source)"/>
                </span>
            </div>
            <div class="checkItem" *ngFor="let d of subData; trackBy:identify">
                <span class="checkText">
                    <p>{{d._source.name}}</p>
                </span>
                <span class="check" *ngIf="checkedBox === false">
                    <input nz-input type="checkbox" class="mr-4" nzStyle="{width: '50px'}" [checked]="isSelected(d)" (change)="onCheckBoxChange($event,d._source)"/>
                </span>
                <span class="check" *ngIf="checkedBox === true">
                    <input nz-input type="checkbox" class="mr-4" nzStyle="{width: '50px'}" [checked]="true" (change)="onCheckBoxChange($event,d._source)"/>
                </span>
            </div>
        </ng-container>
        <ng-container *ngIf="subData.length === 0">
            <div class="center" style="text-align: center; margin: 0 auto; width:50px;">
                <nz-spin nzSimple></nz-spin>
            </div>
        </ng-container>
    </div>
</div>
