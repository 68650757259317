<div class="tourBox" [ngStyle]="{'bottom': position === 'bottom' ? 0 : 'auto', 'top': position === 'top' ? '-60px' : 'auto' }">
    <svg [ngStyle]="{'top': position === 'bottom' ? '210px' : '30px'}" class="tourTip" width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.01859 10.157L12.8624 0.355228C13.5144 -0.184311 14.5 0.279376 14.5 1.12562V19.0956C14.5 19.9014 13.5956 20.3763 12.9323 19.9188L1.08842 11.7506C0.542385 11.3741 0.507585 10.5799 1.01859 10.157Z" fill="#F5FBF8"/>
    </svg>   
    <div class="tourRow">
        <div class="tourIconSide">
            <svg *ngIf="state === 0" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="36" cy="36" r="36" fill="#008138"/>
                <circle cx="36" cy="36" r="24" fill="#229855"/>
                <path d="M36.0003 35.1667C37.8413 35.1667 39.3337 33.6743 39.3337 31.8333C39.3337 29.9924 37.8413 28.5 36.0003 28.5C34.1594 28.5 32.667 29.9924 32.667 31.8333C32.667 33.6743 34.1594 35.1667 36.0003 35.1667Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="29" y="38" width="14" height="6" rx="3" fill="white" stroke="white" stroke-width="1.5"/>
            </svg>                
            <svg *ngIf="state === 1" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="36" cy="36" r="36" fill="#008138"/>
                <circle cx="36" cy="36" r="24" fill="#229855"/>
                <path d="M41.6261 32.4168L39.6844 28.5335C39.3677 27.9085 38.6177 27.6585 38.0011 27.9668C37.9927 27.9668 37.9927 27.9668 37.9927 27.9668L29.1094 32.4002" fill="white"/>
                <path d="M41.6261 32.4168L39.6844 28.5335C39.3677 27.9085 38.6177 27.6585 38.0011 27.9668C37.9927 27.9668 37.9927 27.9668 37.9927 27.9668L29.1094 32.4002" stroke="#229855" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M43 41.1865V42.4576C43 43.8559 41.9719 45 40.7154 45H29.2923L29.2847 44.9992C28.0205 44.9907 27 43.8551 27 42.4568V42.4559V33.5509C26.9924 32.1441 28.0205 31 29.277 31H40.7001H40.6925C41.9491 30.9916 42.9772 32.1356 42.9772 33.5424V34.8136" fill="white"/>
                <path d="M43 41.1865V42.4576C43 43.8559 41.9719 45 40.7154 45H29.2923L29.2847 44.9992C28.0205 44.9907 27 43.8551 27 42.4568V42.4559V33.5509C26.9924 32.1441 28.0205 31 29.277 31H40.7001H40.6925C41.9491 30.9916 42.9772 32.1356 42.9772 33.5424V34.8136" stroke="#229855" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M43.0078 41H43.0011C43.5472 41 44 40.456 44 39.8V36.2C44 35.536 43.5472 35 43.0011 35H40.5039H40.4972C39.1121 35 38 36.336 38 38C38 39.656 39.1121 41 40.4972 40.992L43.0078 41Z" fill="#229855" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M40.9997 37.7177H40.9914C41.158 37.7094 41.2997 37.851 41.2997 38.026C41.2997 38.1927 41.158 38.3344 40.983 38.3344C40.808 38.3344 40.6664 38.1927 40.6664 38.0177C40.658 37.8427 40.7997 37.701 40.9747 37.701C40.9747 37.701 40.9747 37.701 40.9747 37.7002" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>   
            <svg *ngIf="state === 2" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="36" cy="36" r="36" fill="#008138"/>
                <circle cx="36" cy="36" r="24" fill="#229855"/>
                <path d="M42.8745 37.3748V43.5623C42.8745 45.0748 41.637 46.3123 40.1245 46.3123H31.8745L31.8653 46.3114C30.3436 46.3022 29.1153 45.0739 29.1153 43.5614V43.5605V28.4282C29.1061 26.9065 30.3436 25.669 31.8561 25.669H40.1061H40.097C41.6095 25.6598 42.847 26.8973 42.847 28.419" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M41.5 31.875V34.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M40.125 33.25H42.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <g clip-path="url(#clip0_8149_146192)">
                <path d="M35.9993 35.5417C37.0119 35.5417 37.8327 34.7209 37.8327 33.7083C37.8327 32.6958 37.0119 31.875 35.9993 31.875C34.9868 31.875 34.166 32.6958 34.166 33.7083C34.166 34.7209 34.9868 35.5417 35.9993 35.5417Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="32.1504" y="37.1001" width="7.7" height="3.3" rx="1.65" fill="white" stroke="white" stroke-width="0.5"/>
                </g>
                <defs>
                <clipPath id="clip0_8149_146192">
                <rect width="11" height="11" fill="white" transform="translate(30.5 30.5)"/>
                </clipPath>
                </defs>
            </svg> 
            <svg *ngIf="state === 3" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="36" cy="36" r="36" fill="#008138"/>
                <circle cx="36" cy="36" r="24" fill="#229855"/>
                <path d="M29.75 35.875H42.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.5 38.375L32.25 35.875H39.75L39.5 38.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M36.0007 29.5833C36.9211 29.5833 37.6673 28.8371 37.6673 27.9167C37.6673 26.9962 36.9211 26.25 36.0007 26.25C35.0802 26.25 34.334 26.9962 34.334 27.9167C34.334 28.8371 35.0802 29.5833 36.0007 29.5833Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="32.5" y="31" width="7" height="3" rx="1.5" fill="white" stroke="white" stroke-width="0.5"/>
                <path d="M36.0007 41.5833C36.9211 41.5833 37.6673 40.8371 37.6673 39.9167C37.6673 38.9962 36.9211 38.25 36.0007 38.25C35.0802 38.25 34.334 38.9962 34.334 39.9167C34.334 40.8371 35.0802 41.5833 36.0007 41.5833Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="32.5" y="43" width="7" height="3" rx="1.5" fill="white" stroke="white" stroke-width="0.5"/>
                <g clip-path="url(#clip0_8149_146540)">
                <path d="M44.5007 41.5833C45.4211 41.5833 46.1673 40.8371 46.1673 39.9167C46.1673 38.9962 45.4211 38.25 44.5007 38.25C43.5802 38.25 42.834 38.9962 42.834 39.9167C42.834 40.8371 43.5802 41.5833 44.5007 41.5833Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="41" y="43" width="7" height="3" rx="1.5" fill="white" stroke="white" stroke-width="0.5"/>
                </g>
                <g clip-path="url(#clip1_8149_146540)">
                <path d="M27.5007 41.5833C28.4211 41.5833 29.1673 40.8371 29.1673 39.9167C29.1673 38.9962 28.4211 38.25 27.5007 38.25C26.5802 38.25 25.834 38.9962 25.834 39.9167C25.834 40.8371 26.5802 41.5833 27.5007 41.5833Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="24" y="43" width="7" height="3" rx="1.5" fill="white" stroke="white" stroke-width="0.5"/>
                </g>
                <defs>
                <clipPath id="clip0_8149_146540">
                <rect width="10" height="10" fill="white" transform="translate(39.5 37)"/>
                </clipPath>
                <clipPath id="clip1_8149_146540">
                <rect width="10" height="10" fill="white" transform="translate(22.5 37)"/>
                </clipPath>
                </defs>
            </svg>          
            <svg *ngIf="state === 4" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="36" cy="36" r="36" fill="#008138"/>
                <circle cx="36" cy="36" r="24" fill="#229855"/>
                <path d="M36 27C33.6131 27 31.3239 27.9482 29.636 29.636C27.9482 31.3239 27 33.6131 27 36C27 38.3869 27.9482 40.6761 29.636 42.364C31.3239 44.0518 33.6131 45 36 45C38.3869 45 40.6761 44.0518 42.364 42.364C44.0518 40.6761 45 38.3869 45 36C45 33.6131 44.0518 31.3239 42.364 29.636C40.6761 27.9482 38.3869 27 36 27Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M36 42.4287V45.0001" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M36 27V29.5714" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29.5714 36H27" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M44.9992 36H42.4277" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <g clip-path="url(#clip0_8149_146902)">
                <path d="M36.0007 35.0833C36.9211 35.0833 37.6673 34.3371 37.6673 33.4167C37.6673 32.4962 36.9211 31.75 36.0007 31.75C35.0802 31.75 34.334 32.4962 34.334 33.4167C34.334 34.3371 35.0802 35.0833 36.0007 35.0833Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="32.5" y="36.5" width="7" height="3" rx="1.5" fill="white" stroke="white" stroke-width="0.5"/>
                </g>
                <defs>
                <clipPath id="clip0_8149_146902">
                <rect width="10" height="10" fill="white" transform="translate(31 30.5)"/>
                </clipPath>
                </defs>
            </svg>                 
            <svg *ngIf="state === 5" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="36" cy="36" r="36" fill="#008138"/>
                <circle cx="36" cy="36" r="24" fill="#229855"/>
                <path d="M30.4615 33.9231C32.3733 33.9231 33.9231 32.3733 33.9231 30.4615C33.9231 28.5498 32.3733 27 30.4615 27C28.5498 27 27 28.5498 27 30.4615C27 32.3733 28.5498 33.9231 30.4615 33.9231Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M41.5377 33.9231C43.4495 33.9231 44.9993 32.3733 44.9993 30.4615C44.9993 28.5498 43.4495 27 41.5377 27C39.626 27 38.0762 28.5498 38.0762 30.4615C38.0762 32.3733 39.626 33.9231 41.5377 33.9231Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.4615 45.0002C32.3733 45.0002 33.9231 43.4504 33.9231 41.5387C33.9231 39.6269 32.3733 38.0771 30.4615 38.0771C28.5498 38.0771 27 39.6269 27 41.5387C27 43.4504 28.5498 45.0002 30.4615 45.0002Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M41.5377 45.0002C43.4495 45.0002 44.9993 43.4504 44.9993 41.5387C44.9993 39.6269 43.4495 38.0771 41.5377 38.0771C39.626 38.0771 38.0762 39.6269 38.0762 41.5387C38.0762 43.4504 39.626 45.0002 41.5377 45.0002Z" fill="#CEE7D9" stroke="#CEE7D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>                                                 
        </div>
        <div class="tourDetailSide">
            <button class="closeTourButton" (click)="closeTour()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4L4 12" stroke="#7F92A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 4L12 12" stroke="#7F92A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                    
            </button>
            <div class="tourContent">
                <h3>{{tourBoxContent[state].title}}</h3>
                <p>{{tourBoxContent[state].body}}</p>
                <div class="tourFooter">
                  <span>{{state + 1}} of 6</span>
                  <span style="width: 50%;" class="buttonRow">
                    <button *ngIf="state > 0" class="prevBtn" (click)="prev()">Previous</button>
                    <button class="nextBtn" (click)="next()">{{state < 5 ? 'Next' : 'Finish'}}</button>
                  </span>
                </div>
            </div>
        </div>
    </div>        
</div>