import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { WelcomeModalComponent } from './welcome-modal.component';

import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  declarations: [
    WelcomeModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule ,
    NzGridModule
  ],
  exports: [
    WelcomeModalComponent
  ]
})
export class WelcomeModalModule { }
