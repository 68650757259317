import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WindowRef } from './WindowRef';

@Component({
  selector: 'app-zoho-salesiq',
  templateUrl: './zoho-salesiq.component.html',
  styleUrls: ['./zoho-salesiq.component.css'],
  providers: [ WindowRef ]
})
export class ZohoSalesiqComponent implements OnInit {
  //Its Mandatory Field Need to add this domain and widget code inorder to place the widget in your page.
  domain: string = `https://salesiq.zoho.com/widget`;
  widgetCode: string = `14e18a904e11d7c13ce5bde456e8970e02a93388a287a5867f97c6d319b728819e3895aa60cce83fe9f7a6ac2aa6b555`;//`Replace your widgetCode`;
  window: any;
  //validation
  isValid: Boolean = true;

  constructor(
    private winRef: WindowRef,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.validate();
    this.window = winRef.nativeWindow;
   }

  validate(): void {
    if(!this.domain.trim().length || !this.widgetCode.trim().length) {
      this.isValid = false;
    }
  }
  /**
   * @description: Function Responsible for adding the salesiqscript into the application.
   */
  loadSalesiq(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.id = `salesiq_script`;
    script.text = `
        {
          var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {widgetcode: "${this.widgetCode}", values:{},ready:function(){}};
          var d=document;s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="${this.domain}";
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
          d.write("<div id='zsiqwidget'></div>");
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }

  ngOnInit(): void {
    if(this.window && this.window.$zoho) {
      this.showWidget();
    }else {
      //loading the script if the domain and widget code is valid.
      this.isValid && this.loadSalesiq();
    }
  }

  showWidget(): void {
    this.window && this.window.$zoho ?.salesiq ?.floatwindow ?.visible('show')
  }

  hideWidget(): void {
    this.window && this.window.$zoho ?.salesiq ?.floatwindow ?.visible('hide')
  }

  ngOnDestroy() {
    this.hideWidget();
  }

}
