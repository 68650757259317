import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { AuthService } from './_services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { first, filter } from 'rxjs/operators';
import { Observable, Observer, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NzIconService } from 'ng-zorro-antd/icon';
import customIcons from './custom-icons';
import { currencySymbolMap } from './_helpers/constants';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadXHRArgs,
} from 'ng-zorro-antd/upload';
import {
  HttpClient,
  HttpResponse,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SharedService } from './shared/shared.service';
import { WindowRef } from './zoho-salesiq/WindowRef';
import { formatDate, Location } from '@angular/common';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
declare var zcAction: any;
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [WindowRef],
})
export class AppComponent {
  isCollapsed = false;
  clientDetails: any;
  noticebox = false;
  countNotice: any = 0;
  subscription!: Subscription;
  subscription2!: Subscription;
  walletBalance!: Subscription;
  walletCurrency!: Subscription;
  mobileHam!: Subscription;
  window: any;
  whiteMenu = false;

  placement: NzDrawerPlacement = 'left';

  countbalance: any = 0;
  currency: string = '';
  menuMobiles = false;
  menuHam = false;

  capiModalOpen = false;
  capiIntegrationSuccessful = false;

  isVisible: boolean = false;

  logoFile: File | undefined = undefined;
  logoFileRequiredError = false;
  uploadInProgress = false;
  logoUrl!: string;
  absoluteLogoUrl!: string | null;
  initials!: string;

  rolesData: any;

  onboardingModalOpen = false;
  welcomeModalOpen = false;
  tourStarted: boolean = false;
  tourState: number = 0;
  tourTotal: number = 6;

  supportMenuVisible: boolean = false;

  isMobile!: boolean;
  mobileMenuState: boolean = false;

  isCampaignModal = false;
  currencySymbolMap = currencySymbolMap;

  dataUploadNotificationStatus: boolean = false;

  dataUploadNotificationData: any;
  channelList: any;

  routerLink: any = '';
  headerButtonName: any = '';
  headerButtonURL: any = '';
  backButton: any = '';
  user: any;

  constructor(
    private winRef: WindowRef,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private iconService: NzIconService,
    private appService: AppService,
    private msg: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private _location: Location
  ) {
    this.window = winRef.nativeWindow;
    this.iconService.addIconLiteral(
      'custom:intergration',
      customIcons.intergration
    );
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.trackingcode, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.getRouteName(e.url.split(';')[0]);
        this.rolesData = this.authService.getUser();
      });
    this.rolesData = this.authService.getUser();

    // this.authService.getUserRoles().subscribe((response) => {
    //   console.log(response);
    // })
    // first absoluteLogoUrl && initials assignment is to handle setting variables on login as ngoninit is called before authentication
    this.authService.account.subscribe((acc) => {
      this.absoluteLogoUrl =
        acc?.role_id === 1
          ? '../assets/img/tpicon.png'
          : acc?.logo
          ? acc?.logo
          : null;
      this.initials = this.getInitials(acc?.contact_name);
      if (acc) {
        this.showModalFirstTime(acc?.first_time_login);
      }
    });

    this.absoluteLogoUrl =
      this.rolesData?.role_id === 1
        ? '../assets/img/tpicon.png'
        : this.rolesData?.logo
        ? this.rolesData?.logo
        : null;
    this.initials = this.getInitials(this.rolesData.contact_name);
    this.subscription = this.authService.currentNotification.subscribe(
      (countNotice) => (this.countNotice = countNotice)
    );
    this.walletBalance = this.authService.currentBalance.subscribe(
      (countbalance) => (this.countbalance = countbalance.toLocaleString())
    );
    this.walletCurrency = this.authService.currentCurrency.subscribe(
      (currency) => (this.currency = currency)
    );
    this.mobileHam = this.authService.currentHam.subscribe(
      (menu) => (this.menuHam = menu)
    );
    this.getNotification();
    this.getWalletData();
    this.getCapiIntegrationStatus();
    this.sharedService.showCapi.subscribe(
      (status: any) => (this.capiModalOpen = status)
    );
    this.isMobile = this.browserWidth() < 860;
    this.channelList = this.authService.getWhatsappChannelList()?.split('/');
  }

  showWhite() {
    this.whiteMenu = !this.whiteMenu;
  }

  menuSelect(value: string, data?: any) {
    //this.routerLink = value;
    if (data) {
      this.router.navigate([value, data]);
    } else {
      this.router.navigate([value]);
    }
    // this.mobileMenuState = !this.mobileMenuState;
    // if (this.checkIfMobile()) {
    //   this.authService.changeHam(!this.menuHam);
    // }
  }

  getMainMenuState() {
    if (!this.checkIfMobile()) {
      return false;
    } else if (this.checkIfMobile() && this.menuHam) {
      return true;
    } else if (this.checkIfMobile() && !this.menuHam) {
      return false;
    } else {
      return false;
    }
  }

  checkIfMobile() {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    return regexp.test(details);
  }

  shouldShowMobileMenu() {
    return (this.isMobile && this.mobileMenuState) || !this.isMobile;
  }

  onLogout() {
    this.absoluteLogoUrl = null;
    this.whiteMenu = !this.whiteMenu;
    this.authService.logout();
  }

  getWalletData() {
    if (
      localStorage.getItem('auth-token') &&
      this.router['url'] !== '/auth/login' &&
      this.router['url'] !== '/auth/forgetPassword' &&
      this.router['url'] !== '/auth/resetPassword' &&
      this.router['url'] !== '/auth/setPassword' &&
      this.router['url'] !== '/auth/signup' &&
      !this.router['url'].includes('/auth/existingaccount') &&
      !this.router['url'].includes('/auth/newbusiness') &&
      !this.router['url'].includes('/error/index')
    ) {
      let rolesData = this.authService.getUser();

      this.authService
        .getAllwallets(rolesData.business_id)
        .pipe(first())
        .subscribe({
          next: (res) => {
            let responseData: any;
            responseData = res;
            this.countbalance = (
              responseData[0]?.balance || 0
            ).toLocaleString();
            this.currency = responseData[0]?.currency;

            // Update user's currency
            let userData = this.authService.getUser();
            if (!userData.currency) {
              userData = { ...userData, currency: responseData[0]?.currency };

              this.authService.saveUser(userData);
            }
          },
        });
    }
  }

  ngOnDestroy() {
    this.subscription2.unsubscribe();
  }

  getNotification() {
    if (
      localStorage.getItem('auth-token') &&
      this.router['url'] !== '/auth/login' &&
      this.router['url'] !== '/auth/forgetPassword' &&
      this.router['url'] !== '/auth/resetPassword' &&
      this.router['url'] !== '/auth/setPassword' &&
      this.router['url'] !== '/auth/signup' &&
      !this.router['url'].includes('/auth/existingaccount') &&
      !this.router['url'].includes('/auth/newbusiness') &&
      !this.router['url'].includes('/error/index')
    ) {
      this.subscription2 = timer(0, 10000000)
        .pipe(switchMap(async () => this.authService.getNotificationCount()))
        .subscribe((countNotice) => (this.countNotice = countNotice));
    }
  }

  getTheName(str: string) {
    let data = str
      ?.split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), '');
    return data;
  }

  hideNotification() {
    this.noticebox = true;
  }

  isAuthenticated() {
    if (
      localStorage.getItem('auth-token') &&
      this.router['url'] !== '/auth/login' &&
      this.router['url'] !== '/auth/forgetPassword' &&
      this.router['url'] !== '/auth/resetPassword' &&
      this.router['url'] !== '/auth/setPassword' &&
      this.router['url'] !== '/auth/signup' &&
      !this.router['url'].includes('/auth/existingaccount') &&
      !this.router['url'].includes('/auth/newbusiness') &&
      !this.router['url'].includes('/error/index')
    ) {
      this.clientDetails = this.authService.getUser();
      return true;
    }
    return false;
  }
  showSideBar() {
    if (this.router['url'] === '/dashboard/expand') {
      return false;
    }
    return true;
  }

  getCapiIntegrationStatus() {
    if (
      localStorage.getItem('auth-token') &&
      this.router['url'] !== '/auth/login' &&
      this.router['url'] !== '/auth/forgetPassword' &&
      this.router['url'] !== '/auth/resetPassword' &&
      this.router['url'] !== '/auth/setPassword' &&
      this.router['url'] !== '/auth/signup' &&
      !this.router['url'].includes('/auth/existingaccount') &&
      !this.router['url'].includes('/auth/newbusiness') &&
      !this.router['url'].includes('/error/index')
    ) {
      this.appService
        .getCapiIntegrationStatus(this.clientDetails?.id)
        .pipe(first())
        .subscribe({
          next: (res) => {
            let responseData: any;
            responseData = res;

            if (responseData && responseData.isIntegrationComplete) {
              this.capiIntegrationSuccessful = true;
            }
          },
          error: (error) => {},
        });
    }
  }

  goToCapi() {
    // const route = this.capiIntegrationSuccessful ? '/audience/capi-configuration' : '/audience/capi-integration';

    // if(!this.capiIntegrationSuccessful){
    //   this.openCapiModal();
    // }
    const route = '/audience/capi-configurations';
    this.router.navigate([route]);
  }

  openCapiModal() {
    this.capiModalOpen = true;
  }

  closeCapiModal(event: any) {
    this.capiModalOpen = false;
  }

  gotoAudienceInsight() {
    this.router.navigate(['/shared/audience-insight']);
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleFileChange({ file }: NzUploadChangeParam): void {
    this.logoFile = file.originFileObj;
    this.logoFileRequiredError = false;
    this.getBase64(file!.originFileObj!, (img: string) => {
      this.logoUrl = img;
    });
  }

  customUploadRequest = (file: NzUploadXHRArgs) => {
    /* This is added to prevent default behaviour of nz-upload where it tries to upload file immediately*/
    return new Subscription();
  };

  uploadLogo() {
    if (!this.logoFile) {
      this.logoFileRequiredError = true;
      return;
    }

    this.uploadInProgress = true;

    const formData = new FormData();
    formData.append('type', 'logo');
    formData.append('tenant_id', this.rolesData.id);
    formData.append('business_id', this.rolesData.business_id);
    formData.append('datasource', 'client');
    formData.append('file', this.logoFile);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/user/upload-image`,
      formData,
      {
        reportProgress: true,
        withCredentials: false,
      }
    );

    this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            (event as any).percent = (event.loaded / event.total) * 100;
          }
        } else if (event instanceof HttpResponse) {
          /* success */
          if (event.status == 200) {
            setTimeout(() => {
              this.uploadInProgress = false;
              this.notification.create(
                'success',
                'Logo Uploaded',
                `A logo has been created successfully.`
              );
            }, 1000);
            this.isVisible = false;
            if (!event.body.error) {
              this.absoluteLogoUrl = event.body.response.logo;
            }
          }
        }
      },
      (err) => {
        /* error */
        this.uploadInProgress = false;
        this.notification.create('error', 'Logo upload error', '');
      }
    );
  }

  getInitials(name: string = '') {
    let names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  openOnboardingModal() {
    this.onboardingModalOpen = true;
  }

  closeOnboardingModal() {
    this.onboardingModalOpen = false;
  }

  showModalFirstTime(firstTimeLogin: any) {
    if (this.window && this.window.$zoho) {
      zcAction('first_time_login');
    }
    if (firstTimeLogin) {
      setTimeout(() => {
        this.welcomeModalOpen = true;
      }, 5000);
    }
  }

  showWelcomeModal() {
    this.welcomeModalOpen = true;
  }

  handleWelcomeModalEvent($event: string) {
    let action =
      $event === 'tour'
        ? this.handleTourEvent.bind(this)
        : $event === 'tutorial'
        ? this.handleTutorialEvent.bind(this)
        : $event === 'campaign'
        ? this.handleCampaignEvent.bind(this)
        : this.handleDemoEvent.bind(this);
    action();
  }

  handleCampaignEvent() {
    zcAction('start_campaign');
    this.router.navigateByUrl('/campaign/campaignpage');
    // this.isCampaignModal = true;
  }

  handleOkCampaign(): void {
    this.isCampaignModal = false;
  }

  handleCancelCampaign(): void {
    this.isCampaignModal = false;
  }

  handleModalEvent(e: any) {
    e == 1
      ? zcAction('campaign_with_audiencelist')
      : zcAction('campaign_with_custom_audience');
    // this.router.navigate(['/campaign/create-campaign', { id: e }]);
    this.router
      .navigateByUrl('/dashboard', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/campaign/create-campaign', { id: e }]);
      });
  }

  handleWelcomeModalClose() {
    this.welcomeModalOpen = false;
  }

  handleTourEvent() {
    zcAction('tourguide_firstlogin');
    this.tourStarted = true;
  }

  handleTutorialEvent() {
    this.supportMenuVisible = false;
    this.onboardingModalOpen = true;
    // this.toggleMobileMenu();
  }

  handleDemoEvent() {
    // this.toggleMobileMenu();
    window.open(
      'https://terragongroup-terragonltd.zohobookings.com/#/customer/terragonprime1'
    );
  }

  tourNext() {
    if (this.tourState >= this.tourTotal - 1) return;
    this.tourState += 1;
  }

  tourPrev() {
    if (this.tourState <= 0) return;
    this.tourState -= 1;
  }

  endTour() {
    this.tourState = 0;
    this.tourStarted = false;
    this.supportMenuVisible = false;
  }

  supportButtonChange($event: any) {}

  browserWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  open(): void {
    this.menuHam = true;
  }

  close(): void {
    this.menuHam = false;
  }

  navigateBack() {
    this._location.back();
  }

  getRoleName(id: any) {
    switch (id) {
      case 1:
        return 'Global Admin';
      case 2:
        return 'System Admin';
      case 3:
        return 'Department Admin';
      case 4:
        return 'Department User';
      default:
        return '';
    }
  }

  getRouteName(route: any): any {
    route = route.split(':')[0];
    switch (route) {
      case '/dashboard':
        this.routerLink = 'Dashboard';
        //this.headerButtonName = (this.authService.getUser().role_id === 4 || this.authService.getUser().role_id === 3) ? 'Create Campaign' : null;
        this.headerButtonName = '';
        this.headerButtonURL = '/campaign/campaignpage';
        this.backButton = '';
        break;
      case '/campaign/campaign-details':
      case '/campaign/dlr':
        this.routerLink = 'Campaigns';
        this.headerButtonName =
          this.authService.getUser().role_id === 4 ||
          this.authService.getUser().role_id === 3
            ? 'Create Campaign'
            : null;
        this.headerButtonURL = '/campaign/campaignpage';
        this.backButton = 'Campaigns';
        break;
      case '/campaign/campaign-list':
        this.routerLink = 'Campaigns';
        this.headerButtonName =
          this.authService.getUser().role_id === 4 ||
          this.authService.getUser().role_id === 3
            ? 'Create Campaign'
            : null;
        this.headerButtonURL = '/campaign/campaignpage';
        this.backButton = '';
        break;
      case '/campaign/campaignpage':
        this.routerLink = 'Campaigns';
        this.headerButtonName = null;
        this.backButton = 'Campaigns';
        break;
      case '/campaign/campaign-reports':
        this.routerLink = 'Messages';
        //this.headerButtonName = (this.authService.getUser().role_id === 4 || this.authService.getUser().role_id === 3) ? 'Create Campaign' : null;
        this.headerButtonName = '';
        this.headerButtonURL = '/campaign/campaignpage';
        this.backButton = '';
        break;
      case '/client/client-list':
        this.routerLink = 'Departments';
        this.headerButtonName =
          this.authService.getUser().role_id === 3
            ? null
            : 'Create new department';
        this.headerButtonURL = '/client/create-client';
        this.backButton = '';
        break;
      case '/client/create-client':
        this.routerLink = 'Departments';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = 'Departments';
        break;
      case '/client/client-details/':
        this.routerLink = 'Departments';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = 'Departments';
        break;
      case '/user/user-list':
        this.routerLink = 'Users';
        this.headerButtonName = 'Add new user';
        this.headerButtonURL = '/user/create-user';
        this.backButton = '';
        break;
      case '/user/create-user':
        this.routerLink = 'Users';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = 'Users';
        break;
      case '/user/user-details/':
        this.routerLink = 'Users';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = 'Users';
        break;
      case '/account/clientAccount':
      case '/account/clientAccount/senderId':
      case '/account/clientAccount/users':
        this.routerLink = 'Settings';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = '';
        break;
      case '/rates/list':
        this.routerLink = 'Rates';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = '';
        break;
      case '/message-report/list':
        this.routerLink = 'Reports';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = '';
        break;
      case '/audit-logs/list':
        this.routerLink = 'Audit Logs';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = '';
        break;
      case '/audiences/landing':
      case '/audiences/insights':
        this.routerLink = 'Curated Audiences';
        this.headerButtonName = '';
        this.headerButtonURL = '';
        this.backButton = '';
        break;
    }
  }
}
