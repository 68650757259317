import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'notificationDatePipe'})
export class NotificationDatePipe implements PipeTransform {
  transform(value: Date): string {
    let curDate = new Date();
    let msgDate = new Date(value);
    let diff = (curDate.getTime() - msgDate.getTime())/1000;
    if(diff<60){
      return "now"
    }else if(diff<(3600)){
      return Math.floor(diff/60) + " min ago"
    }else if(diff<(86400)){
      let hours = Math.floor(diff/3600);
      return hours + (hours > 1 ? " hours" : " hour") + " ago";
    }else{
      let days = Math.floor(diff/86400);
      return days + (days > 1 ? " days" : " day") + " ago";
    }
  }
}