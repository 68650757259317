import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tour-box',
  templateUrl: './tour-box.component.html',
  styleUrls: ['./tour-box.component.css']
})
export class TourBoxComponent implements OnInit {

  @Input() state: number = 0;
  @Input() position: 'top'|'bottom' = 'bottom'; 
  @Output() increaseState = new EventEmitter<number>();
  @Output() decreaseState = new EventEmitter<number>();
  @Output() tourEnd = new EventEmitter();

  tourBoxContent = [
    {
      title: "Create Sender ID",
      body: "Create your message Sender ID (message header), which should be your business name. Sender IDs are approved 24 hours after creation."
    },
    {
      title: "Fund your campaigns",
      body: "Fund your wallet and buy SMS, and WhatsApp credits for your campaigns. Update your present payment plan."
    },
    {
      title: "Upload customer data",
      body: "Manually upload profiles or contacts and capture events from your website and other sources."
    },
    {
      title: "Insights and custom audience",
      body: "Create custom audiences using Terragon's database, enrich your profiles and view your customers insights (gender, age range, location etc)."
    },
    {
      title: "Set up and launch campaigns",
      body: "Setup and launch SMS and WhatsApp campaigns."
    },
    {
      title: "View account activities",
      body: "View a graphical representation of your customer profiles, total campaigns deployed and amount spent. "
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  next(){
    if (this.state >= 5) {
      this.tourEnd.emit();
      return;
    }
    this.increaseState.emit();
  }

  prev(){
    if (this.state <= 0) {
      return;
    }
    this.decreaseState.emit();
  }

  closeTour(){
    this.tourEnd.emit();
  }

}
