<div class="modal">
    <nz-modal [(nzVisible)]="open" [nzFooter]='null' nzTitle="" nzWidth="642px" nzClassName="onboardingModal" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
        <div>
            <div class="topVideo">
                <!-- <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.0002 58.6668C46.7278 58.6668 58.6668 46.7278 58.6668 32.0002C58.6668 17.2726 46.7278 5.3335 32.0002 5.3335C17.2726 5.3335 5.3335 17.2726 5.3335 32.0002C5.3335 46.7278 17.2726 58.6668 32.0002 58.6668Z" fill="#009D4F"/>
                    <path d="M26.6665 21.3335L42.6665 32.0002L26.6665 42.6668V21.3335Z" fill="white"/>
                </svg>-->
                <!-- <video width="320" height="240" controls>
                    <source  src="../../../assets/onboarding/prime-on-boarding-video-short.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video> -->
                <iframe width="320" height="240"
                    src="https://www.youtube.com/embed/WRc2GOMqjV0?autoplay=1&mute=0">
                </iframe>
            </div>
            <div class="bottomText">
                <h2>Getting started with Terragon Prime</h2>
                <p>Watch our video to learn more about how Prime can help you setup and run campaigns for your future customers.</p>
            </div>
        </div>
      </ng-container>
    </nz-modal>
  </div>
