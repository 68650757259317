import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

const baseUrl = `${environment.apiUrl}`;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
  }),
};

@Injectable({ providedIn: 'root' })
export class AppService {
  
  constructor(private router: Router, private http: HttpClient) {}

  getCapiIntegrationStatus(clientId: string) {
    return this.http.post(`https://sr83vbf70f.execute-api.eu-west-1.amazonaws.com/dev/clientid`, { id: clientId }, httpOptions);
  }

}