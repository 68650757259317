import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { InboxNotificationService } from 'src/app/_services/inbox-notification.service';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
// import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  //@Input() title!: string;
  // @Input() btnText!: string;
  // @Input() view: boolean = false;
  // @Input() breadcrumb: any[] = [];
  // @Input() btnTextgroup: any[] = [];
  // @Output() routeUrl = new EventEmitter();
  // mobileHam!: Subscription;

  @Input() headerTitle!: string;
  @Input() headerButtonName!: string;
  @Input() headerButtonURL!: string;

  whatsappNotificationList: any[] = [];
  notificationList: any[] = [];
  dateFormatInterval: any;
  channelList: any;
  dataUploadNotificationStatus: boolean = false;
  dataUploadNotificationData: any = null;
  campaignStatusNotificationStatus: boolean = false;
  campaignStatusNotificationData: any = null;
  popOverVisibility: boolean = false;
  popOverVisibilitynotification: boolean = false;
  subjectSubscriber: any;
  // menuHam = true;

  constructor(private inboxService: InboxNotificationService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.channelList = this.authService.getWhatsappChannelList()?.split('/');
    this.inboxService.startListener();
    // this.mobileHam = this.authService.currentHam.subscribe(menu => this.menuHam = menu);
    this.subjectSubscriber = this.inboxService.subject.subscribe((value: any) => {
      if (value.type === 'data_upload') {
        this.dataUploadNotificationStatus = true;
        this.dataUploadNotificationData = value;
        this.closeNotificationOnTime();
      } else if (value.type === 'campaign_status' || value.type === 'waba_creation') {
        this.notificationList.unshift(value);
      } else {
        this.whatsappNotificationList.unshift(value);
        this.sortNotificationByTime();
      }
    })
  }

  inboxNotificationChange(event: any) {
    if (event == true) {
      this.popOverVisibility = true;
      //this.whatsappNotificationList = [];
    }
  }

  notificationChange(event: any) {
    if (event == true) {
      this.popOverVisibilitynotification = true;
      //this.whatsappNotificationList = [];
    }
  }

  ngOnDestroy() {
    this.notificationList = [];
    this.whatsappNotificationList = [];
    this.subjectSubscriber.unsubscribe();
    this.inboxService.stopListener();
  }

  closeNotificationOnTime() {
    let interval = setInterval(() => {
      this.dataUploadNotificationStatus = false;
      clearInterval(interval);
    }, 3000)
  }

  closeDataUploadNotification() {
    this.dataUploadNotificationStatus = false;
  }

  redirectToDataUploadDetail() {
    this.dataUploadNotificationStatus = false;
    this.router.navigate(['/shared/audience-insight', { id: this.dataUploadNotificationData.sengmentId, source: this.dataUploadNotificationData.source, userId: this.dataUploadNotificationData.userId, businessId: this.dataUploadNotificationData.businessId, totalProfiles: this.dataUploadNotificationData.totalProfiles, segmentName: this.dataUploadNotificationData.segmentName, uploadMode: this.dataUploadNotificationData.uploadMode, uploadedProfiles: this.dataUploadNotificationData.uploadedProfiles, status: this.dataUploadNotificationData.status }]);
  }

  redirectToCampaignDetail() {
    this.dataUploadNotificationStatus = false;
    this.router.navigate(['/campaign/campaign-details', { id: this.dataUploadNotificationData.sengmentId }]);
  }

  showConversation(message: any) {
    this.inboxService.markMessagesAsread(message.channel);
    this.deleteChannelMessagesFromList(message.channel);
    this.popOverVisibility = false;
    this.router.navigate(['/inbox/whatsapp', { id: message.channel }]);
  }

  markAllMessagesAsRead() {
    this.whatsappNotificationList = [];
    this.inboxService.markMessagesAsread(null);
  }

  sortNotificationByTime() {
    this.whatsappNotificationList.sort(function (x, y) {
      let d1 = new Date(x.timeStamp);
      let d2 = new Date(y.timeStamp);
      return d2.getTime() - d1.getTime();
    })
  }

  // toggleMobileMenu() {
  //   this.authService.changeHam(true);
  // }

  deleteChannelMessagesFromList(channel: any) {
    this.whatsappNotificationList = this.whatsappNotificationList.filter((value, index, arr) => {
      return value.channel !== channel;
    })
  }

  showConversationNotification(message: any) {
    if (message.id) {
      this.deleteChannelMessagesFromListNotification(message.id);
      this.popOverVisibilitynotification = false;
      this.router.navigate(['/campaign/campaign-details', { id: message.id }]);
    }
  }

  deleteChannelMessagesFromListNotification(id: any) {
    this.notificationList = this.notificationList.filter((value, index, arr) => {
      return value.id !== id;
    })
  }

  // toggletoRoute(e: any) {
  //   this.routeUrl.emit(e);
  // }

  // toggleView(e: any) {
  //   this.routeUrl.emit(e);
  // }

  onLogout() {
    this.authService.logout();
  }

  gotoRoute() {
    //this.router.navigate(['/campaign/campaignpage']);
    this.router.navigate([this.headerButtonURL])
  }

}
