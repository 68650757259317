import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-createCampign-modal',
  templateUrl: './createCampaign-modal.component.html',
  styleUrls: ['./createCampaign-modal.component.css']
})
export class CreateCampaignModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Output() closeModal = new EventEmitter();
  @Output() createCampaignModalEvent = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

  handleOk(): void {
    this.open = false;
  }

  handleCancel(): void {
    this.closeModal.emit();
    this.open = false;
  }

  selectOption(value: string) {
    this.handleCancel();
    this.createCampaignModalEvent.emit(value);
  }

}
