<div class="header-block">
  <!-- <div nz-col class="gutter-row" [nzXs]="3" [nzSm]="0" [nzMd]="0" [nzLg]="0" [nzXl]="0">
      <div class="mobileMenuTrigger" (click)="toggleMobileMenu()">
        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="5" y1="8.12891" x2="27" y2="8.12891" stroke="#073763" stroke-width="2" stroke-linecap="round"/>
          <line x1="5" y1="15.1289" x2="27" y2="15.1289" stroke="#073763" stroke-width="2" stroke-linecap="round"/>
          <line x1="5" y1="22.1289" x2="27" y2="22.1289" stroke="#073763" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div> 
    </div> -->
  <!-- <div nz-col class="gutter-row" [nzXs]="14" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12">
      <div class="headerTitle">
        <div *ngIf="title">{{title}}</div>
        <div *ngIf="!title">
          <nz-breadcrumb>
            <nz-breadcrumb-item *ngFor="let data of breadcrumb.slice(0, breadcrumb.length -1)" [routerLink]="[data ?.route]" style="cursor: pointer">{{data.routeName}} <i nz-icon nzType="caret-right"></i></nz-breadcrumb-item>
            <nz-breadcrumb-item>{{ breadcrumb[breadcrumb.length -1].routeName}}</nz-breadcrumb-item>
          </nz-breadcrumb>
        </div>
      </div>
    </div> -->
  <!-- <div *ngIf="btnText" class="mainBtn">
        <a (click)="toggletoRoute($event)"  nz-button nzType="primary">{{btnText}}</a>
      </div>
      <div *ngIf="btnTextgroup.length > 0" class="mainBtn">
        <nz-button-group>
          <button nz-button nzType="primary" [ngStyle]="{'background-color': !view ? '#343864' : '#fff', 'color': !view ? '#fff' : '#343864'}" (click)="toggleView('client')">{{btnTextgroup[0]}}</button>
          <button nz-button nzType="primary" [ngStyle]="{'background-color': view ? '#343864' : '#fff', 'color': view ? '#fff' : '#343864'}" (click)="toggleView('terragon')">{{btnTextgroup[1]}}</button>
        </nz-button-group>
      </div> -->
  <!-- <div class="alertBtn">
          <div class="alertBtnBox">
            <div class="trgn-prm-header-inbox-notif-badge" *ngIf="whatsappNotificationList.length>0"></div>
            <a *ngIf="channelList != ''"
            nz-button
            nz-popover
            nzType="text"
            nzTooltipTitle="Whatsapp Inbox" 
            nzTooltipPlacement="bottom"
            nz-tooltip
            nzTooltipColor="#7F92A4"
            [nzPopoverContent]="whatsappNotificationTemplate"
            nzPopoverPlacement="bottomRight"
            nzPopoverTrigger="click"
            [(nzPopoverVisible)]="popOverVisibility"
            (nzPopoverVisibleChange)="inboxNotificationChange($event)">
            <img class="trgn-prm-header-icon" src="../assets/header-icon/fi_message-square.svg" alt="" /></a>
            <ng-template #whatsappNotificationTemplate>
              <div style="width: 400px; max-height: 348px; overflow: hidden;">
                <div class="trgn-prm-inbox-notif-header-text-container">
                  <span>New messages ({{whatsappNotificationList.length}})</span>
                  <span *ngIf="whatsappNotificationList.length > 0" class="trgn-prm-notification-mark-as-read-span" (click)="markAllMessagesAsRead()">Mark all as read</span>
                </div>
                <div style="max-height: calc(348px - 62px); overflow: auto;">
                  <div *ngFor="let message of whatsappNotificationList" (click)="showConversation(message);" style="cursor: pointer;">
                    <div class="trgn-prm-inbox-notif-message-container">
                      <div>
                        <div class="trgn-prim-inbox-notif-msg-bdg-div"></div>
                      </div>
                      <div style="width: 100%;">
                        <div class="trgn-prim-inbox-notif-msg-txt">
                          <span class="trgn-prim-inbox-notif-msg-txt-span">{{message.senderName}}</span>
                          <span class="trgn-prim-inbox-notif-msg-time-span">{{message.timeStamp | notificationDatePipe}}</span>
                        </div>
                        <div class="trgn-prim-inbox-notif-msg-txt trgn-prim-inbox-notif-msg-txt-overflow">
                          <span>{{message.content}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="whatsappNotificationList.length == 0">
                    <div class="trgn-prm-inbox-no-message-txt">
                      <span>No new messages</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="alertBtnBox">
            <div class="trgn-prm-header-inbox-notif-badge" *ngIf="notificationList.length>0"></div>
            <a
            nz-button
            nz-popover
            nzType="text"
            nzTooltipTitle="Notifications" 
            nzTooltipPlacement="bottom"
            nz-tooltip
            nzTooltipColor="#7F92A4"
            [nzPopoverContent]="notificationTemplate"
            nzPopoverPlacement="bottomRight"
            nzPopoverTrigger="click"
            [(nzPopoverVisible)]="popOverVisibilitynotification"
            (nzPopoverVisibleChange)="notificationChange($event)">
            <img class="trgn-prm-header-icon" src="../assets/header-icon/fi_bell.svg" alt="" /></a>
            <ng-template #notificationTemplate>
              <div style="width: 400px; max-height: 348px; overflow: hidden;">
                <div class="trgn-prm-inbox-notif-header-text-container">
                  <span>New messages ({{notificationList.length}})</span>
                  <span *ngIf="notificationList.length > 0" class="trgn-prm-notification-mark-as-read-span" (click)="markAllMessagesAsRead()">Mark all as read</span>
                </div>
                <div style="max-height: calc(348px - 62px); overflow: auto;">
                  <div *ngFor="let message of notificationList" (click)="showConversationNotification(message);" style="cursor: pointer;">
                    <div class="trgn-prm-inbox-notif-message-container">
                      <div>
                        <div class="trgn-prim-inbox-notif-msg-bdg-div"></div>
                      </div>
                      <div style="width: 100%;">
                        <div class="trgn-prim-inbox-notif-msg-txt">
                          <span class="trgn-prim-inbox-notif-msg-txt-span">{{message.message}}</span>
                        </div>
                        <div class="trgn-prim-inbox-notif-msg-txt trgn-prim-inbox-notif-msg-txt-overflow">
                          <span>{{message.timetoken | notificationDatePipe}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="notificationList.length == 0">
                    <div class="trgn-prm-inbox-no-message-txt">
                      <span>No new messages</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
      </div> -->
  <div style="display: flex; align-items: center;">{{headerTitle}}</div>
  <div class="header-button-container">
    <div *ngIf="headerButtonName" (click)="gotoRoute()" class="header-action-button">{{headerButtonName}}</div>
    <!-- <div (click)="onLogout()" class="trgn-prm-logout-button">Logout</div> -->
  </div>
</div>
<div class="trgn-prm-data-upload-notif-parent-div" *ngIf="dataUploadNotificationStatus">
  <div class="trgn-prm-data-upload-notif-content-div">
    <div style="display: flex; gap: 10px;">
      <div><img src="../../assets/header-icon/tick-circle.svg" alt=""></div>
      <div style="display: flex; flex-direction: column; gap: 10px;">
        <span class="trgn-prm-data-upload-notif-header">Data Upload complete</span>
        <span class="trgn-prm-data-upload-notif-desc">Data upload is now completed.
          <!-- <span (click)="redirectToDataUploadDetail()"
            class="trgn-prm-data-upload-redirect-link">View detailed insight.</span>  -->
        </span>
      </div>
    </div>
    <div><img class="trgn-prm-data-upload-notif-close" (click)="closeDataUploadNotification()"
        src="../../assets/header-icon/Close button.svg" alt=""></div>
  </div>
</div>