<div class="modal deviceOsModal">
    <nz-modal [(nzVisible)]="open" [nzFooter]='null' nzTitle="" nzWidth="642px" nzClassName="modal welcomeModal" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
        <div class="row" >
            <div class="half">
                <h3 class="modalTitle">Choose {{title}} models to target</h3>
                <p class="modalSub"><small>Select from a wide range of {{title}} to target</small></p>
                <div class="main">
                    <nz-input-group [nzPrefix]="prefixIconSearch">
                        <input type="text" nz-input [(ngModel)]="searchString" (ngModelChange)="startSearch($event)"  placeholder="search {{title}}" />
                    </nz-input-group>
                    <ng-template #prefixIconSearch>
                        <i nz-icon nzType="search"></i>
                    </ng-template>
                    <!--  -->
                    <div class="listMain">
                        <ng-container>
                            <div *ngFor="let d of data; trackBy:identify">
                                <app-droplist [data]="d" [subData]="subData" [selected]="selected" [state]="isActive(d._id)" (setActive)="setActive($event)" (addDevice)="addDeviceToSelected($event)"></app-droplist>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="data.length === 0">
                            <nz-spin nzSimple></nz-spin>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="half" style="padding-top: 30px;">
                <div class="selectedPillList">
                    <p class="selectedCount"><b>Selected {{title}}({{selected.length}})</b></p>
                    <div class="selectedList">
                        <div class="selectedItem" *ngFor="let s of selected">
                            <p>{{s.name}} <span *ngIf="s.level == 1">(All Models)</span></p>
                            <span class="removePill" (click)="removeSelected(s)">
                                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.5 2L2.5 6" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2.5 2L6.5 6" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                                    
                            </span>
                        </div>
                    </div>
                </div>
                <div class="buttonRow">
                    <button (click)="done()" nz-button nzType="primary">Done</button>
                </div>
            </div>
        </div>
      </ng-container>
    </nz-modal>
  </div>