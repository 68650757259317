import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZohoSalesiqComponent } from './zoho-salesiq.component';
import { NgxEchartsModule } from 'ngx-echarts';
@NgModule({
  declarations: [
    ZohoSalesiqComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [ZohoSalesiqComponent],
})
export class ZohoModule { }
